import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ReactDOM from 'react-dom/client';
import App from './App';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { AppProvider } from './context/AppContext'; // Import AppProvider
import apolloClient from '../apollo-client';
import { ApolloProvider } from '@apollo/client';
import theme from './Theming/DefaultTheme';
import globalStyles from './Theming/globalStyles';
var container = document.getElementById('root');
if (container) {
    var root = ReactDOM.createRoot(container);
    root.render(_jsxs(ThemeProvider, { theme: theme, children: [_jsx(CssBaseline, {}), globalStyles, " ", _jsx(AppProvider, { children: _jsx(ApolloProvider, { client: apolloClient, children: _jsx(App, {}) }) })] }));
}
