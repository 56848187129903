import { createTheme } from '@mui/material/styles';
var theme = createTheme({
    palette: {
        primary: {
            extraLight: '#e3f2fd',
            light: '#90caf9',
            main: '#2980b9', // Royal Blue
            dark: '#0d47a1',
            contrastText: '#FFFFFF', // White
        },
        secondary: {
            extraLight: '#e0f2f1',
            light: '#4db6ac',
            main: '#00897b', // Tropical Rain Forest Green
            dark: '#00695c',
            contrastText: '#FFFFFF', // White
        },
        text: {
            primary: '#2B2B2B', // Mine Shaft Grey
            secondary: '#757575',
            disabled: '#bdbdbd',
        },
    },
    typography: {
        h1: {
            fontSize: '2.225rem',
            '@media (max-width:600px)': {
                fontSize: '1.75rem',
            },
        },
        h2: {
            fontSize: '1.85rem',
            '@media (max-width:600px)': {
                fontSize: '1.5rem',
            },
        },
        h3: {
            fontSize: '1.6rem',
            '@media (max-width:600px)': {
                fontSize: '1.25rem',
            },
        },
        h4: {
            fontSize: '1.35rem',
            '@media (max-width:600px)': {
                fontSize: '1rem',
            },
        },
        h5: {
            fontSize: '1.1rem',
            '@media (max-width:600px)': {
                fontSize: '0.875rem',
            },
        },
        h6: {
            fontSize: '0.975rem',
            '@media (max-width:600px)': {
                fontSize: '0.75rem',
            },
        },
        body1: {
            fontSize: '1.1rem',
            fontWeight: 400,
            lineHeight: 1.75,
            '@media (max-width:600px)': {
                fontSize: '0.875rem',
            },
        },
        body2: {
            fontSize: '0.975rem',
            fontWeight: 400,
            lineHeight: 1.57,
            '@media (max-width:600px)': {
                fontSize: '0.75rem',
            },
        },
        subtitle1: {
            fontSize: '1.1rem',
            fontWeight: 400,
            lineHeight: 1.75,
            '@media (max-width:600px)': {
                fontSize: '0.875rem',
            },
        },
        subtitle2: {
            fontSize: '0.975rem',
            fontWeight: 500,
            lineHeight: 1.57,
            '@media (max-width:600px)': {
                fontSize: '0.75rem',
            },
        },
        caption: {
            fontSize: '0.85rem',
            fontWeight: 400,
            lineHeight: 1.66,
            '@media (max-width:600px)': {
                fontSize: '0.625rem',
            },
        },
        overline: {
            fontSize: '0.85rem',
            fontWeight: 400,
            lineHeight: 2.66,
            '@media (max-width:600px)': {
                fontSize: '0.625rem',
            },
        },
        button: {
            fontSize: '0.975rem',
            fontWeight: 500,
            lineHeight: 1.75,
            '@media (max-width:600px)': {
                fontSize: '0.75rem',
            },
        },
        fontFamily: [
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
});
export default theme;
