import { encoding_for_model } from 'tiktoken';
import * as sw from 'stopword';
/**
 * Converts milliseconds to seconds with 3 decimal places.
 * @param {number} milliseconds - The time in milliseconds.
 * @returns {number} - The time in seconds with 3 decimal places.
 */
export var convertMillisecondsToSeconds = function (milliseconds) {
    return parseFloat((milliseconds / 1000).toFixed(3));
};
/**
 * Calculates the number of tokens in a given string of text.
 * @param {string} text - The input text.
 * @returns {number} - The number of tokens.
 */
export var getTokenSummary = function (text, languageModel, removeStopWords) {
    // Use the encoding for the specific model you are targeting
    var encoding = encoding_for_model(languageModel.encoding); // Replace with the model you are using
    // Tokenize the input text
    var tokenIds = encoding.encode(text);
    var tokens = text.split(' ');
    var tokenSummery = {
        originalTokenCount: tokenIds.length,
        tokenCount: tokenIds.length,
        wordCount: tokens.length,
        modelTokenLimit: languageModel.tokenLimit,
        tokens: tokens,
    };
    // Filter out the stop words
    var filteredTokens = sw.removeStopwords(text.split(' '));
    if (removeStopWords === true) {
        tokenSummery.tokenCount = filteredTokens.length;
        tokenSummery.tokens = filteredTokens;
    }
    return tokenSummery;
};
/**
 * Converts a language model object to a select input option.
 * @param object
 * @returns
 */
export var convertToSelectInputOption = function (object) {
    return {
        value: object.value,
        label: object === null || object === void 0 ? void 0 : object.label,
    };
};
/**
 * Converts an array of language model objects to an array of select input options.
 * @param objects
 * @returns
 */
export var convertToSelectInputOptions = function (objects) {
    return objects.map(convertToSelectInputOption);
};
