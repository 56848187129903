import { jsx as _jsx } from "react/jsx-runtime";
import { Link } from '@mui/material';
var TextLink = function (_a) {
    var children = _a.children, onClick = _a.onClick;
    return (_jsx(Link, { rel: 'noopener', sx: {
            color: '#2196f3', // Lighter blue
            cursor: 'pointer', // Change cursor to pointer on hover
            fontWeight: 'bold',
            '&:hover': {
                color: '#0d47a1', // Darker blue on hover
            },
        }, onClick: onClick, children: children }));
};
export default TextLink;
