var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Box } from '@mui/material';
var adSectionStyle = {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 150,
    padding: 0,
    backgroundColor: '#222222',
};
var AdSection = function (_a) {
    var style = _a.style, children = _a.children;
    return _jsx(Box, { style: __assign(__assign({}, adSectionStyle), style), children: children });
};
export default AdSection;
