import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Typography, Divider, useMediaQuery, useTheme, } from '@mui/material';
import { CHANGE_LOG_ENTRIES } from '../../../constants';
import { getPageSectionStyle } from '../../Theming/globalStyles';
var dividerStyle = {
    width: 100,
    margin: '0px 0px 20px 0px',
};
var ChangeLogSection = function () {
    var theme = useTheme();
    var isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    return (_jsxs(Box, { style: getPageSectionStyle(isMobile), children: [_jsx(Typography, { variant: 'h3', component: 'h2', gutterBottom: true, children: "Change Log" }), _jsx(Divider, { style: dividerStyle }), _jsx(Box, { children: CHANGE_LOG_ENTRIES.map(function (entry, index) { return (_jsxs(Box, { style: { marginBottom: 20 }, children: [_jsxs(Typography, { variant: 'h6', component: 'h4', children: ["Version ", entry.version] }), _jsx(Typography, { variant: 'body2', color: 'textSecondary', children: entry.date }), _jsx(Typography, { variant: 'body1', children: entry.description })] }, index)); }) })] }));
};
export default ChangeLogSection;
