var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useState, useEffect, } from 'react';
import moment from 'moment-timezone';
// Create the context with default values
var AppContext = createContext(undefined);
// Custom hook to access the AppContext
export var useAppContext = function () {
    var context = useContext(AppContext);
    if (!context) {
        throw new Error('useAppContext must be used within an AppProvider');
    }
    return context;
};
// Provider component to wrap your app
export var AppProvider = function (_a) {
    var _b;
    var children = _a.children;
    var _c = useState({
        user: null,
        isAuthenticated: false,
    }), componentState = _c[0], setComponentState = _c[1];
    var updateComponentState = function (newComponentState) {
        setComponentState(function (componentState) {
            return __assign(__assign({}, componentState), newComponentState);
        });
    };
    useEffect(function () {
        // Set the user's timezone
        moment.tz.setDefault(moment.tz.guess());
    }, []);
    var loginUser = function (onLoginSuccess, onLoginFailure) {
        //Simulate a login request
        var user = {
            firstName: 'Evan',
            lastName: 'Bacon',
            email: 'evanmellison@gmail.com',
        };
        updateComponentState({ user: user, isAuthenticated: true });
        onLoginSuccess && onLoginSuccess();
        onLoginFailure && onLoginFailure();
    };
    var logoutUser = function (onLogoutSuccess) {
        //Simulate a logout request
        updateComponentState({ user: null, isAuthenticated: false });
        onLogoutSuccess && onLogoutSuccess();
    };
    var updateUserInfo = function (user, onUpdateUserInfoSuccess, onUpdateUserInfoFailure) {
        onUpdateUserInfoSuccess && onUpdateUserInfoSuccess();
        onUpdateUserInfoFailure && onUpdateUserInfoFailure();
    };
    var value = {
        user: (_b = componentState.user) !== null && _b !== void 0 ? _b : null,
        isAuthenticated: componentState.isAuthenticated,
        login: function (onLoginSuccess, onLoginFailure) {
            loginUser(onLoginSuccess, onLoginFailure);
        },
        logout: function (onLogoutSuccess) {
            logoutUser(onLogoutSuccess);
        },
        updateUserInfo: function (user, onUpdateUserInfoSuccess, onUpdateUserInfoFailure) {
            updateUserInfo(user, onUpdateUserInfoSuccess, onUpdateUserInfoFailure);
        },
    };
    return _jsx(AppContext.Provider, { value: value, children: children });
};
