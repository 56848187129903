import { jsx as _jsx } from "react/jsx-runtime";
import { Typography } from '@mui/material';
var TextHighlight = function (_a) {
    var children = _a.children, color = _a.color;
    return (_jsx("span", { children: _jsx(Typography, { variant: 'body1', component: 'span', sx: {
                color: color ? color : '#2196f3', // Lighter blue
                fontWeight: 'bold',
            }, children: children }) }));
};
export default TextHighlight;
