import { jsx as _jsx } from "react/jsx-runtime";
import GlobalStyles from '@mui/material/GlobalStyles';
export var getPageSectionStyle = function (isMobile) { return ({
    width: isMobile ? '100%' : '70%', // Adjust width based on screen size
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'white',
    padding: isMobile ? ' 50px 30px ' : '50px 80px',
    borderRadius: 10,
    minHeight: '60vh',
    boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
}); };
var globalStyles = (_jsx(GlobalStyles, { styles: {
        '*': {
            margin: 0,
            padding: 0,
            boxSizing: 'border-box',
        },
        body: {
            backgroundColor: '#f4f4f4',
            color: '#333',
            fontFamily: 'Roboto, sans-serif',
        },
        html: {
            'scroll-behavior': 'smooth',
        },
    } }));
export default globalStyles;
