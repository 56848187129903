var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { AppBar, Button, Toolbar, Typography, IconButton, Drawer, List, ListItem, ListItemText, useMediaQuery, useTheme, Box, MenuItem, Menu, } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useLocation, useNavigate } from 'react-router-dom';
import theme from '../Theming/DefaultTheme';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
var buttonStyle = {
    display: 'flex',
    alignItems: 'baseline',
    textTransform: 'none',
    color: theme.palette.text.primary,
};
var drawerHeaderStyle = {
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    padding: '16px',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
};
var AppNavigation = function () {
    var navigate = useNavigate();
    var location = useLocation();
    var theme = useTheme();
    var isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    var _a = useState({
        drawerOpen: false,
        legalDropdownAnchorEl: null,
        homeDropdownAnchorEl: null,
    }), componentState = _a[0], setComponentState = _a[1];
    var updateComponentState = function (newComponentState) {
        setComponentState(function (componentState) {
            return __assign(__assign({}, componentState), newComponentState);
        });
    };
    var handleHomeMenuOpen = function (event) {
        updateComponentState(__assign(__assign({}, componentState), { homeDropdownAnchorEl: event.currentTarget }));
    };
    var handleHomeMenuClose = function () {
        updateComponentState(__assign(__assign({}, componentState), { homeDropdownAnchorEl: null }));
    };
    var handleLegalMenuOpen = function (event) {
        updateComponentState(__assign(__assign({}, componentState), { legalDropdownAnchorEl: event.currentTarget }));
    };
    var handleLegalMenuClose = function () {
        updateComponentState(__assign(__assign({}, componentState), { legalDropdownAnchorEl: null }));
    };
    var handleNavigate = function (path, hash) {
        navigate(path);
        if (hash) {
            window.location.hash = hash;
        }
        updateComponentState(__assign(__assign({}, componentState), { drawerOpen: false, legalDropdownAnchorEl: null, homeDropdownAnchorEl: null }));
    };
    var toggleDrawer = function (open) { return function () {
        updateComponentState(__assign(__assign({}, componentState), { drawerOpen: open }));
    }; };
    var getButtonStyle = function (paths) {
        var currentPathAtLocation = paths.includes(location.pathname);
        return currentPathAtLocation
            ? __assign(__assign({}, buttonStyle), { color: theme.palette.primary.main }) : __assign(__assign({}, buttonStyle), { color: theme.palette.text.primary });
    };
    var drawer = (_jsxs(Box, { sx: {
            width: 250,
            backgroundColor: 'white',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
        }, role: 'presentation', onClick: toggleDrawer(false), onKeyDown: toggleDrawer(false), children: [_jsx(Box, { style: drawerHeaderStyle, children: _jsx(Typography, { variant: 'h6', children: "Token Quotas" }) }), _jsxs(List, { children: [_jsx(ListItem, { component: 'button', onClick: function () { return handleNavigate('/'); }, children: _jsx(ListItemText, { primary: 'Home' }) }), _jsx(ListItem, { component: 'button', onClick: function () { return handleNavigate('/', '#about'); }, children: _jsx(ListItemText, { primary: 'About' }) }), _jsx(ListItem, { component: 'button', onClick: function () { return handleNavigate('/', '#faq'); }, children: _jsx(ListItemText, { primary: 'FAQ' }) }), _jsx(ListItem, { component: 'button', onClick: function () { return handleNavigate('/', '#change-log'); }, children: _jsx(ListItemText, { primary: 'Change Log' }) }), _jsx(ListItem, { component: 'button', onClick: function () { return handleNavigate('/', '#contact'); }, children: _jsx(ListItemText, { primary: 'Contact' }) }), _jsx(ListItem, { component: 'button', onClick: function () { return handleNavigate('/terms'); }, children: _jsx(ListItemText, { primary: 'Terms' }) }), _jsx(ListItem, { component: 'button', onClick: function () { return handleNavigate('/privacy'); }, children: _jsx(ListItemText, { primary: 'Privacy' }) })] })] }));
    return (_jsx(AppBar, { position: 'fixed', sx: {
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
            backgroundColor: 'white',
        }, children: _jsxs(Toolbar, { children: [_jsxs(Box, { sx: { display: 'flex', alignItems: 'center', flexGrow: 1 }, children: [_jsx(IconButton, { color: 'inherit', edge: 'start', onClick: function () { return handleNavigate('/', '#token-counter'); }, children: _jsx("img", { src: 'https://strsharedeus.blob.core.windows.net/apps/tokenquotas/images/logo-192x192.png', alt: 'Token Quotas Logo', style: { width: 40, height: 40 } }) }), _jsx(Typography, { variant: 'h4', component: 'div', sx: { flexGrow: 1, color: theme.palette.text.primary }, children: "Token Quotas" })] }), isMobile ? (_jsxs(_Fragment, { children: [_jsx(IconButton, { color: 'inherit', edge: 'start', onClick: toggleDrawer(true), sx: { mr: 2, color: theme.palette.text.primary }, children: _jsx(MenuIcon, {}) }), _jsx(Drawer, { anchor: 'left', open: componentState.drawerOpen, onClose: toggleDrawer(false), children: drawer })] })) : (_jsxs(_Fragment, { children: [_jsx(Button, { color: 'inherit', onClick: handleHomeMenuOpen, style: getButtonStyle([
                                '/',
                                '/faq',
                                '/blogs',
                                '/about',
                                '/contact',
                            ]), endIcon: _jsx(FontAwesomeIcon, { icon: faAngleDown, style: {
                                    transition: 'transform 0.3s',
                                    transform: componentState.homeDropdownAnchorEl
                                        ? 'rotate(180deg)'
                                        : 'rotate(0deg)',
                                    fontSize: 12,
                                } }), children: _jsx(Typography, { variant: 'button', children: "Home" }) }), _jsxs(Menu, { anchorEl: componentState.homeDropdownAnchorEl, open: Boolean(componentState.homeDropdownAnchorEl), onClose: handleHomeMenuClose, children: [_jsx(MenuItem, { onClick: function () { return handleNavigate('/', '#about'); }, style: getButtonStyle(['/#about']), children: "About" }), _jsx(MenuItem, { onClick: function () { return handleNavigate('/', '#faq'); }, style: getButtonStyle(['/#faq']), children: "FAQ" }), _jsx(MenuItem, { onClick: function () { return handleNavigate('/', '#change-log'); }, style: getButtonStyle(['/#change-log']), children: "Change Log" }), _jsx(MenuItem, { onClick: function () { return handleNavigate('/', '#contact'); }, style: getButtonStyle(['/#contact']), children: "Contact" })] }), _jsx(Button, { color: 'inherit', onClick: handleLegalMenuOpen, style: getButtonStyle(['/privacy', '/terms']), endIcon: _jsx(FontAwesomeIcon, { icon: faAngleDown, style: {
                                    transition: 'transform 0.3s',
                                    transform: componentState.legalDropdownAnchorEl
                                        ? 'rotate(180deg)'
                                        : 'rotate(0deg)',
                                    fontSize: 12,
                                } }), children: _jsx(Typography, { variant: 'button', children: "Legal" }) }), _jsxs(Menu, { anchorEl: componentState.legalDropdownAnchorEl, open: Boolean(componentState.legalDropdownAnchorEl), onClose: handleLegalMenuClose, children: [_jsx(MenuItem, { onClick: function () { return handleNavigate('/privacy'); }, style: getButtonStyle(['/privacy']), children: "Privacy" }), _jsx(MenuItem, { onClick: function () { return handleNavigate('/terms'); }, style: getButtonStyle(['/terms']), children: "Terms" })] })] }))] }) }));
};
export default AppNavigation;
