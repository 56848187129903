/*App*/
export var FONT_FAMILY = 'Roboto, Public Sans, sans-serif';
export var TEST_TOKEN_TEXT = 'The rapid advancement of technology has dramatically transformed the way we live and work. From smartphones to artificial intelligence, these innovations have not only made our lives more convenient but have also reshaped industries across the globe. As businesses adapt to this new digital landscape, the need for continuous learning and upskilling has never been more critical. Those who embrace change and keep pace with technological advancements will be well-positioned for future success, while those who resist may find themselves struggling to remain competitive in an increasingly dynamic world.';
export var CHANGE_LOG_ENTRIES = [
    {
        version: '1.0.0',
        date: 'October 2024',
        description: 'Initial Release of Token Quotas; Basic token counting functionality; User-friendly interface with responsive design.',
    },
];
export var HELP_LINKS = [
    {
        url: 'https://platform.openai.com/docs/concepts',
        title: 'OpenAI Documentation',
    },
    {
        url: 'https://platform.openai.com/tokenizer',
        title: 'OpenAI Tokenizer',
    },
    {
        url: 'https://www.ibm.com/docs/en/watsonx/saas?topic=solutions-tokens',
        title: 'IBM WhatIs Tokenization',
    },
];
export var LANGUAGE_MODELS = [
    {
        value: 1,
        label: 'GPT-4',
        encoding: 'gpt-4-turbo',
        description: 'GPT-4 is the fourth iteration of OpenAI’s Generative Pre-trained Transformer (GPT) series. It is a state-of-the-art language model that can generate human-like text based on the input it receives. GPT-4 has 1 trillion parameters and is capable of performing a wide range of natural language processing tasks.',
        tokenLimit: 8192,
    },
    {
        value: 2,
        label: 'GPT-4 Turbo',
        encoding: 'gpt-4-turbo',
        description: "Represents OpenAI's GPT-4 Turbo model, the successor to GPT-3 with 1 trillion parameters",
        tokenLimit: 8192,
    },
    {
        value: 3,
        label: 'GPT-3.5',
        encoding: 'gpt-3.5-turbo',
        description: 'GPT-3.5 is the third iteration of OpenAI’s Generative Pre-trained Transformer (GPT) series. It is a powerful language model that can generate human-like text based on the input it receives. GPT-3.5 has 175 billion parameters and is capable of performing a wide range of natural language processing tasks.',
        tokenLimit: 4096,
    },
    {
        value: 4,
        label: 'Davinci',
        encoding: 'text-davinci-003',
        description: "Represents GPT-3's Davinci model, one of the most capable models in the GPT-3 series for tasks that require a deep understanding of language",
        tokenLimit: 4096,
    },
    {
        value: 5,
        label: 'Babbage',
        encoding: 'text-babbage-001',
        description: "Represents GPT-3's Babbage model, optimized for tasks that require a more focused and concise response",
        tokenLimit: 4096,
    },
];
export var BOOLEAN_CHECKBOX_OPTIONS = [
    { value: 1, label: 'Yes' },
    { value: 0, label: 'No' },
];
export var HOWTO_STEPS = [
    {
        title: 'Step 1',
        text: 'Select the language model that you want to use for token counting. Different models may count tokens differently.',
    },
    {
        title: 'Step 2',
        text: 'Enter the text you want to analyze in the input field labeled "Text".',
    },
    {
        title: 'Step 3',
        text: 'Click the "Analyze" button to calculate the token count and other data about the text you entered.',
    },
];
