var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Divider, Typography, useMediaQuery, useTheme, } from '@mui/material';
import ContactForm from '../forms/ContactForm';
import theme from '../../Theming/DefaultTheme';
var contactSectionStyle = {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '50px 20px',
    backgroundColor: theme.palette.primary.main,
};
var dividerStyle = {
    width: 100,
    margin: '0px 0px 20px 0px',
    borderColor: 'white',
};
var ContactSection = function (_a) {
    var style = _a.style;
    var theme = useTheme();
    var isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    var contactFormContainerStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth: isMobile ? '100%' : '50%',
    };
    return (_jsx(Box, { style: __assign(__assign({}, contactSectionStyle), style), children: _jsxs(Box, { style: contactFormContainerStyle, children: [_jsx(Typography, { variant: 'h3', component: 'h2', color: 'white', gutterBottom: true, children: "Contact Us" }), _jsx(Divider, { style: dividerStyle }), _jsx(Typography, { variant: 'body1', style: { marginBottom: 20, textAlign: 'center', color: 'white' }, children: "We'd love to hear from you! Whether you have a question, feedback, or just want to say hello, our team is here to help. Please fill out the form below, and we'll get back to you as soon as possible. Your thoughts and inquiries are important to us, and we look forward to connecting with you." }), _jsx(ContactForm, {})] }) }));
};
export default ContactSection;
