var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Button, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { getTokenSummary } from '../../../utils';
import SelectInput from '../inputs/SelectInput';
import { BOOLEAN_CHECKBOX_OPTIONS, LANGUAGE_MODELS } from '../../../constants';
var textarea = {
    width: '100%',
    minHeight: 550,
    overflowY: 'scroll',
    resize: 'vertical',
    padding: '10px',
    borderRadius: '4px',
    border: '1px solid #ccc',
    fontSize: '16px',
    outline: 'none', // Prevent the default focus outline
};
var errorTextarea = __assign(__assign({}, textarea), { border: '2px solid red' });
var TokenCounterForm = function () {
    var _a;
    var _b = useState({
        languageModel: LANGUAGE_MODELS[0],
        removeStopWords: false,
        tokenSummary: {
            originalTokenCount: 0,
            tokenCount: 0,
            wordCount: 0,
            modelTokenLimit: 0,
            tokens: [],
        },
    }), componentState = _b[0], setComponentState = _b[1];
    var updateComponentState = function (newComponentState) {
        setComponentState(function (componentState) {
            return __assign(__assign({}, componentState), newComponentState);
        });
    };
    var _c = useForm({
        defaultValues: {
            tokenText: '',
        },
    }), register = _c.register, handleSubmit = _c.handleSubmit, errors = _c.formState.errors;
    var onSubmit = function (formData) { return __awaiter(void 0, void 0, void 0, function () {
        var newTokenSummary;
        return __generator(this, function (_a) {
            console.log("Token text: ".concat(formData.tokenText, "\n Language model: ").concat(componentState.languageModel.value, "\n Remove stop words: ").concat(componentState.removeStopWords));
            newTokenSummary = getTokenSummary(formData.tokenText, componentState.languageModel, componentState.removeStopWords);
            updateComponentState(__assign(__assign({}, componentState), { tokenSummary: newTokenSummary }));
            return [2 /*return*/];
        });
    }); };
    var onLanguageModelChange = function (selectedOption) {
        var selectedLanguageModel = LANGUAGE_MODELS.find(function (model) { return model.value === selectedOption.value; });
        if (selectedLanguageModel) {
            updateComponentState(__assign(__assign({}, componentState), { languageModel: selectedLanguageModel }));
        }
    };
    var onRemoveStopWordsChange = function (selectedOption) {
        updateComponentState(__assign(__assign({}, componentState), { removeStopWords: Boolean(selectedOption.value) }));
    };
    return (_jsxs(Grid, { container: true, spacing: 2, style: { width: '100%' }, children: [_jsx(Grid, { size: { xs: 12, md: 12 }, children: _jsxs(Grid, { container: true, direction: 'column', spacing: 1, style: { width: '100%' }, children: [_jsxs(Grid, { size: { lg: 4, xs: 12 }, children: [_jsx("label", { style: { color: 'white' }, children: "Language Model:" }), _jsx(Box, { style: { width: 300 }, children: _jsx(SelectInput, { label: 'Language Model', options: LANGUAGE_MODELS, isClearable: false, isSearchable: false, placeholderText: 'Select language model', disabled: false, isLoading: false, currentValue: componentState.languageModel, handleChange: onLanguageModelChange }) })] }), _jsxs(Grid, { size: { lg: 4, xs: 12 }, children: [_jsx("label", { style: { color: 'white' }, children: "Remove Stop Words:" }), _jsx(Box, { style: { width: 300 }, children: _jsx(SelectInput, { label: 'Remove Stop Words', options: BOOLEAN_CHECKBOX_OPTIONS, isClearable: false, isSearchable: false, placeholderText: 'Remove stop words', disabled: false, isLoading: false, currentValue: {
                                            label: componentState.removeStopWords ? 'Yes' : 'No',
                                            value: Number(componentState.removeStopWords),
                                        }, handleChange: onRemoveStopWordsChange }) })] }), _jsx(Grid, { size: { lg: 4, xs: 12 } })] }) }), _jsxs(Grid, { size: { lg: 5, xs: 12 }, children: [_jsx(Typography, { variant: 'h5', sx: { color: 'white' }, children: "Text" }), _jsx(Box, { children: _jsxs("form", { noValidate: true, onSubmit: handleSubmit(onSubmit), style: { width: '100%' }, children: [_jsx("textarea", __assign({ style: errors.tokenText ? errorTextarea : textarea, id: 'tokenText', placeholder: 'Enter text here' }, register('tokenText', {
                                    required: {
                                        value: true,
                                        message: 'This field is required',
                                    },
                                    maxLength: {
                                        value: 10000,
                                        message: 'This field must be less than 10000 characters',
                                    },
                                }))), errors.tokenText && (_jsx(Typography, { color: 'red', variant: 'body2', children: errors.tokenText.message }))] }) })] }), _jsx(Grid, { size: { lg: 2, xs: 12 }, children: _jsxs(Box, { style: {
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: 6,
                    }, children: [_jsx(Button, { type: 'submit', variant: 'contained', color: 'primary', onClick: handleSubmit(onSubmit), sx: {
                                width: 130,
                                marginBottom: 3,
                                backgroundColor: '#ffffff', // Light background color
                                color: '#000000', // Dark text color
                                '&:hover': {
                                    backgroundColor: '#f0f0f0', // Slightly darker on hover
                                },
                                '&:active': {
                                    backgroundColor: '#e0e0e0', // Even darker on active
                                },
                            }, children: "Analyze" }), _jsx(Typography, { variant: 'h5', sx: { color: 'white' }, children: "Token Limit: ".concat(componentState.tokenSummary.modelTokenLimit) }), _jsx(Typography, { variant: 'h5', sx: { color: 'white' }, children: "Word Count: ".concat(componentState.tokenSummary.wordCount) }), _jsx(Typography, { variant: 'h5', sx: { color: 'white' }, children: "Token Count: ".concat(componentState.tokenSummary.tokenCount) })] }) }), _jsx(Grid, { size: { lg: 5, xs: 12 }, children: _jsxs(Box, { children: [_jsx(Typography, { variant: 'h5', sx: { color: 'white' }, children: "Tokens" }), _jsx(Typography, { children: _jsx("textarea", { style: textarea, id: 'tokenCount', readOnly: true, placeholder: 'Tokens from text', value: (_a = componentState === null || componentState === void 0 ? void 0 : componentState.tokenSummary) === null || _a === void 0 ? void 0 : _a.tokens.join(' ') }) })] }) })] }));
};
export default TokenCounterForm;
