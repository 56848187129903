import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Container, Typography, Box, Divider } from '@mui/material';
var containerStyle = {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '70px 40px',
};
var dividerStyle = {
    width: 100,
    margin: '0px 0px 20px 0px',
};
var Blogs = function () {
    return (_jsx(Container, { children: _jsxs(Box, { style: containerStyle, children: [_jsx(Typography, { variant: 'h3', component: 'h2', gutterBottom: true, children: "Blog Posts" }), _jsx(Typography, { variant: 'body2', color: 'textSecondary', children: "These are some of our blog posts" }), _jsx(Divider, { style: dividerStyle })] }) }));
};
export default Blogs;
