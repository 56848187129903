var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { colors, Box } from '@mui/material';
import Select, { components, } from 'react-select';
import theme from '../../Theming/DefaultTheme';
var SelectInput = function (_a) {
    var disabled = _a.disabled, currentValue = _a.currentValue, handleChange = _a.handleChange, isClearable = _a.isClearable, isSearchable = _a.isSearchable, options = _a.options, label = _a.label, isLoading = _a.isLoading, placeholderText = _a.placeholderText;
    var Menu = function (props) {
        return _jsx(components.Menu, __assign({}, props, { children: props.children }));
    };
    var MenuList = function (props) {
        return (_jsx(components.MenuList, __assign({}, props, { children: props.children })));
    };
    var IndicatorSeparator = function (_a) {
        var innerProps = _a.innerProps;
        return (_jsx("span", __assign({ style: {
                display: 'none',
                alignSelf: 'stretch',
                backgroundColor: colors.grey[300],
                marginBottom: 8,
                marginTop: 8,
                width: 1,
            } }, innerProps)));
    };
    return (_jsx(Box, { style: {
            width: '100%',
        }, children: _jsx(Select, { classNamePrefix: 'select', options: options, value: currentValue, isDisabled: disabled, isClearable: isClearable, isSearchable: isSearchable, onChange: function (event) { return handleChange(event); }, placeholder: placeholderText, closeMenuOnScroll: false, closeMenuOnSelect: true, isLoading: isLoading, menuPlacement: 'bottom', maxMenuHeight: 200, noOptionsMessage: function (_a) {
                var inputValue = _a.inputValue;
                return !inputValue ? "No ".concat(label, "s Found") : 'No results found';
            }, components: {
                Menu: Menu,
                MenuList: MenuList,
                IndicatorSeparator: IndicatorSeparator,
            }, styles: {
                container: function (provided, state) { return (__assign(__assign({}, provided), { width: '100%' })); },
                control: function (provided, state) { return (__assign(__assign({}, provided), { paddingTop: 2, paddingRight: 8, paddingBottom: 2, boxShadow: 'none', fontFamily: 'Roboto, Public Sans, sans-serif', fontWeight: 400, '&:hover': {
                        borderColor: '#2B2B2B',
                    }, '&:focus': {
                        borderColor: theme.palette.primary.main,
                    } })); },
                menu: function (provided) { return (__assign(__assign({}, provided), { borderRadius: 5, marginTop: 0, padding: 5 })); },
                menuList: function (provided) { return (__assign(__assign({}, provided), { fontFamily: 'Roboto, Public Sans, sans-serif', fontWeight: 500, padding: 0 })); },
            } }) }));
};
export default SelectInput;
