import { jsx as _jsx } from "react/jsx-runtime";
import { Fab } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import AppToolTip from '../wrappers/AppToolTip';
var BackToTopButton = function (_a) {
    var onClick = _a.onClick;
    return (_jsx(AppToolTip, { text: 'Back to top', children: _jsx(Fab, { color: 'primary', size: 'small', onClick: onClick, sx: {
                position: 'fixed',
                bottom: 16,
                right: 16,
                zIndex: 1000,
            }, children: _jsx(KeyboardArrowUpIcon, {}) }) }));
};
export default BackToTopButton;
