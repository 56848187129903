var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Container, useMediaQuery, useTheme } from '@mui/material';
import PageSection from '../components/sections/PageSection';
import TokenCounterForm from '../components/forms/TokenCounterForm';
import AdSection from '../components/sections/AdSection';
import AboutSection from '../components/sections/AboutSection';
import ChangeLogSection from '../components/sections/ChangeLogSection';
import FaqSection from '../components/sections/FaqSection';
import ContactSection from '../components/sections/ContactSection';
import BackToTopButton from '../components/buttons/BackToTopButton';
var Home = function () {
    var theme = useTheme();
    var isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    var pageContainerStyle = {
        width: '100%',
        paddingTop: '64px', // Adjust this value based on the height of your AppBar
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: 0,
        padding: 0,
    };
    var pageSectionStyle = {
        padding: isMobile ? 20 : 50, // Adjust padding based on screen size
        minHeight: '60vh',
    };
    var tokenCounterPageSectionStyle = __assign(__assign({}, pageSectionStyle), { backgroundColor: theme.palette.primary.main });
    var handleScrollToTop = function () {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    return (_jsxs(Container, { style: pageContainerStyle, maxWidth: false, children: [_jsx(PageSection, { sectionId: 'token-counter', style: tokenCounterPageSectionStyle, children: _jsx(TokenCounterForm, {}) }), _jsx(AdSection, { style: { padding: 0 }, children: _jsx(Box, {}) }), _jsx(PageSection, { sectionId: 'about', style: pageSectionStyle, children: _jsx(AboutSection, {}) }), _jsx(PageSection, { sectionId: 'faq', style: pageSectionStyle, children: _jsx(FaqSection, {}) }), _jsx(PageSection, { sectionId: 'change-log', style: pageSectionStyle, children: _jsx(ChangeLogSection, {}) }), _jsx(PageSection, { sectionId: 'contact', style: { padding: 0 }, children: _jsx(ContactSection, {}) }), _jsx(BackToTopButton, { onClick: handleScrollToTop })] }));
};
export default Home;
