import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { AppBar, Button, Toolbar, Typography, IconButton, Drawer, List, ListItem, ListItemText, useMediaQuery, useTheme, Box, } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';
import theme from '../Theming/DefaultTheme';
var buttonStyle = {
    textTransform: 'none',
    color: theme.palette.text.primary,
};
var drawerHeaderStyle = {
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    padding: '16px',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
};
var AppNavigation = function () {
    var navigate = useNavigate();
    var theme = useTheme();
    var isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    var _a = useState(false), drawerOpen = _a[0], setDrawerOpen = _a[1];
    var handleNavigate = function (path, hash) {
        navigate(path);
        if (hash) {
            window.location.hash = hash;
        }
        setDrawerOpen(false); // Close the drawer after navigation
    };
    var toggleDrawer = function (open) { return function () {
        setDrawerOpen(open);
    }; };
    var drawer = (_jsxs(Box, { sx: {
            width: 250,
            backgroundColor: 'white',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
        }, role: 'presentation', onClick: toggleDrawer(false), onKeyDown: toggleDrawer(false), children: [_jsx(Box, { style: drawerHeaderStyle, children: _jsx(Typography, { variant: 'h6', children: "Token Quotas" }) }), _jsxs(List, { children: [_jsx(ListItem, { component: 'button', onClick: function () { return handleNavigate('/', 'about'); }, children: _jsx(ListItemText, { primary: 'About' }) }), _jsx(ListItem, { component: 'button', onClick: function () { return handleNavigate('/', '#faq'); }, children: _jsx(ListItemText, { primary: 'FAQ' }) }), _jsx(ListItem, { component: 'button', onClick: function () { return handleNavigate('/', '#change-log'); }, children: _jsx(ListItemText, { primary: 'Change Log' }) }), _jsx(ListItem, { component: 'button', onClick: function () { return handleNavigate('/', '#contact'); }, children: _jsx(ListItemText, { primary: 'Contact' }) })] })] }));
    return (_jsx(AppBar, { position: 'fixed', sx: {
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
            backgroundColor: 'white',
        }, children: _jsxs(Toolbar, { children: [_jsxs(Box, { sx: { display: 'flex', alignItems: 'center', flexGrow: 1 }, children: [_jsx(IconButton, { color: 'inherit', edge: 'start', onClick: function () { return handleNavigate('/', '#token-counter'); }, children: _jsx("img", { src: 'https://strsharedeus.blob.core.windows.net/apps/tokenquotas/images/logo-192x192.png', alt: 'Token Quotas Logo', style: { width: 40, height: 40 } }) }), _jsx(Typography, { variant: 'h4', component: 'div', sx: { flexGrow: 1, color: theme.palette.text.primary }, children: "Token Quotas" })] }), isMobile ? (_jsxs(_Fragment, { children: [_jsx(IconButton, { color: 'inherit', edge: 'start', onClick: toggleDrawer(true), sx: { mr: 2, color: theme.palette.text.primary }, children: _jsx(MenuIcon, {}) }), _jsx(Drawer, { anchor: 'left', open: drawerOpen, onClose: toggleDrawer(false), children: drawer })] })) : (_jsxs(_Fragment, { children: [_jsx(Button, { color: 'inherit', style: buttonStyle, onClick: function () { return handleNavigate('/', 'about'); }, children: _jsx(Typography, { variant: 'button', children: "About" }) }), _jsx(Button, { color: 'inherit', style: buttonStyle, onClick: function () { return handleNavigate('/', '#faq'); }, children: _jsx(Typography, { variant: 'button', children: "FAQ" }) }), _jsx(Button, { color: 'inherit', style: buttonStyle, onClick: function () { return handleNavigate('/', '#change-log'); }, children: _jsx(Typography, { variant: 'button', children: "Change Log" }) }), _jsx(Button, { color: 'inherit', style: buttonStyle, onClick: function () { return handleNavigate('/', '#contact'); }, children: _jsx(Typography, { variant: 'button', children: "Contact" }) })] }))] }) }));
};
export default AppNavigation;
